import { ColumnsType } from 'antd/es/table';
import { getCourseRatings, GetCourseRatingsResponse } from 'api';
import { DateFormatter } from 'components/date_formatter';
import { DataTable } from 'components/tables';
import { Link } from 'react-router-dom';
import { coursePath } from 'utils/paths';

const courseRatingColumns: ColumnsType<GetCourseRatingsResponse> = [
  {
    key: 'course',
    render: (_value, record) => (
      <Link to={coursePath(record.course.id)}>{record.course.short_title}</Link>
    ),
    title: 'Course'
  },
  {
    dataIndex: 'rating',
    key: 'rating',
    title: 'Rating'
  },
  {
    dataIndex: 'comment',
    key: 'comment',
    title: 'Comment'
  },
  {
    dataIndex: 'created_at',
    key: 'created_at',
    render: (value: GetCourseRatingsResponse['created_at']) => <DateFormatter value={value} />,
    title: 'Created at'
  }
];

interface Props {
  user_id: string;
}

const CourseRatingsTable = ({ user_id }: Props) => (
  <DataTable
    columns={courseRatingColumns}
    getMethod={getCourseRatings}
    getParams={{
      user_id
    }}
  />
);

export default CourseRatingsTable;
