import { getScores } from 'api';
import { DataTable } from 'components/tables';

import { scoreColumns } from '../user_components';

interface Props {
  user_info_id: string;
}

const ScoresHistoryTable = ({ user_info_id }: Props) => (
  <DataTable
    columns={scoreColumns.filter(col => col.key !== 'earned_at')}
    filters={[
      {
        key: 'score_type',
        label: 'Community',
        options: [
          { text: '', value: null },
          { text: 'bipoc', value: 'bipoc' },
          { text: 'lgbq', value: 'lgbq' },
          { text: 'tgnc', value: 'tgnc' }
        ]
      }
    ]}
    getMethod={getScores}
    getParams={{
      is_active: false,
      user_info_id
    }}
  />
);

export default ScoresHistoryTable;
