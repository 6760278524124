import { ColumnsType } from 'antd/es/table';
import { getCommunicationInfos, GetCommunicationInfosResponse } from 'api/communication_infos';
import { ActionsColumn, DataTable } from 'components/tables';
import { communicationInfoPath } from 'utils/paths';

const emailsColumns: ColumnsType<GetCommunicationInfosResponse> = [
  {
    dataIndex: 'email_type',
    key: 'email_type',
    title: 'E-mail type'
  },
  {
    dataIndex: 'user',
    key: 'user',
    render: (value: GetCommunicationInfosResponse['user']) => value?.email,
    title: 'Addressee'
  },
  {
    dataIndex: 'status',
    key: 'status',
    title: 'Status'
  },
  {
    dataIndex: 'email_delivered_at',
    key: 'email_delivered_at',
    title: 'Delivered at',
    width: '15%'
  },
  {
    dataIndex: 'email_opened_at',
    key: 'email_opened_at',
    title: 'Opened at',
    width: '15%'
  },
  {
    dataIndex: 'email_link_clicked_at',
    key: 'email_link_clicked_at',
    title: 'Link clicked at',
    width: '15%'
  },
  {
    dataIndex: 'sg_machine_open',
    key: 'sg_machine_open',
    title: 'SG opened at',
    width: '15%'
  },
  {
    key: 'actions',
    render: (_value, record) => (
      <ActionsColumn
        record={record}
        showPath={communicationInfoPath(record.id.toString())}
      />
    ),
    title: 'Actions'
  }
];

interface Props {
  user_id: string;
}

const CommunicationsTable = ({ user_id }: Props) => (
  <DataTable
    columns={emailsColumns}
    getMethod={getCommunicationInfos}
    getParams={{
      user_id
    }}
  />
);

export default CommunicationsTable;
