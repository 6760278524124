import { CopyOutlined, ExportOutlined, EyeFilled, EyeInvisibleFilled } from '@ant-design/icons';
import { Button, message, Modal, Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { getNotifications, GetNotificationsResponse } from 'api/notifications';
import { BooleanIcon } from 'components/boolean_icon';
import { DateFormatter } from 'components/date_formatter';
import { DataTable } from 'components/tables';
import { useState } from 'react';
import styled from 'styled-components';

const CodeBlock = styled.div`
  white-space: pre-wrap;
  max-width: 90vh;
  max-height: 90vh;
  overflow: auto;
  font-family: monospace;
  background-color: #f0f0f0;
  color: #333;
  padding: 1rem;
  border-radius: 0.5rem;
`;

const NotificationData = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  padding: 0.5rem;
  border: 1px solid #e0e0e0;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
`;

const DataActionButtonsWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
`;

interface Props {
  user_id: string;
}

const NotificationsTable = ({ user_id }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [notificationInModal, setNotificationInModal] = useState<
    GetNotificationsResponse | undefined
  >(undefined);

  const copyDataToClipboard = (data: GetNotificationsResponse['data']) => {
    navigator.clipboard.writeText(JSON.stringify(data, null, 4));
    message.success('Notification data copied to clipboard.');
  };

  const showModal = (notification: GetNotificationsResponse) => {
    setIsModalOpen(true);
    setNotificationInModal(notification);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    setNotificationInModal(undefined);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setNotificationInModal(undefined);
  };

  const notificationsColumns: ColumnsType<GetNotificationsResponse> = [
    {
      dataIndex: 'template_name',
      key: 'template_name',
      title: 'Template name'
    },
    {
      dataIndex: 'read',
      key: 'read',
      render: (value: GetNotificationsResponse['read']) => <BooleanIcon value={value} />,
      title: 'Read'
    },
    {
      dataIndex: 'data',
      key: 'data',
      render: (_value, notification: GetNotificationsResponse) => (
        <DataActionButtonsWrapper>
          <Button onClick={() => showModal(notification)}>
            View data <ExportOutlined />
          </Button>
          <Button onClick={() => copyDataToClipboard(notification.data)}>
            Copy data <CopyOutlined />
          </Button>
        </DataActionButtonsWrapper>
      ),
      title: 'Data'
    },
    {
      dataIndex: 'created_at',
      key: 'created_at',
      render: (value: GetNotificationsResponse['created_at']) => <DateFormatter value={value} />,
      title: 'Created at'
    }
  ];

  return (
    <>
      <DataTable
        columns={notificationsColumns}
        getMethod={getNotifications}
        getParams={{
          user_id
        }}
      />
      <Modal
        footer={(_, { CancelBtn, OkBtn }) => (
          <>
            <Button onClick={() => copyDataToClipboard(notificationInModal?.data ?? {})}>
              Copy data <CopyOutlined />
            </Button>
            <CancelBtn />
            <OkBtn />
          </>
        )}
        onCancel={handleCancel}
        onOk={handleOk}
        open={isModalOpen}
        title="Notification data"
        width={900}
      >
        <NotificationData>
          <div>{notificationInModal?.template_name}</div>
          <div>
            <Tag
              color={notificationInModal?.read === true ? 'green' : 'blue'}
              style={{ marginRight: '0.5rem' }}
            >
              {notificationInModal?.read === true ? (
                <EyeFilled style={{ marginRight: '0.25rem' }} />
              ) : (
                <EyeInvisibleFilled style={{ marginRight: '0.25rem' }} />
              )}
              {notificationInModal?.read === true ? 'Read' : 'Unread'}
            </Tag>
            <DateFormatter value={notificationInModal?.created_at ?? ''} />
          </div>
        </NotificationData>
        <CodeBlock>{JSON.stringify(notificationInModal?.data, null, 4)}</CodeBlock>
      </Modal>
    </>
  );
};

export default NotificationsTable;
