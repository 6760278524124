import { getScores } from 'api';
import { DataTable } from 'components/tables';

import { scoreColumns } from '../user_components';

interface Props {
  user_info_id: string;
}

const BenchmarksSummaryTable = ({ user_info_id }: Props) => (
  <DataTable
    columns={scoreColumns}
    getMethod={getScores}
    getParams={{
      is_active: true,
      user_info_id
    }}
    pagination={false}
    style={{ marginBottom: 20 }}
    title={() => <h2 style={{ margin: '0' }}>Benchmarks summary</h2>}
  />
);

export default BenchmarksSummaryTable;
