import { Card } from 'antd';
import { GetUserResponse } from 'api';
import { CardEntryList } from 'components/cards/card_entry_list';

interface Props {
  culturalCompetences: GetUserResponse['user_info']['cultural_competences'];
}

const CulturalCompetenciesTable = ({ culturalCompetences }: Props) => (
  <>
    {culturalCompetences.map((cultComp, index) => (
      <Card
        key={index}
        title={cultComp.competence_type}
      >
        <CardEntryList
          fieldsMap={[
            { key: 'competence_type', title: 'Competence type' },
            {
              key: 'confidence_level',
              title: 'Confidence level'
            },
            {
              key: 'formal_education_training',
              title: 'Formal education training'
            },
            {
              key: 'professional_experience',
              title: 'Professional experience'
            },
            {
              key: 'community_of_interest',
              title: 'Community of interest'
            },
            {
              key: 'lived_experience',
              title: 'Lived experience'
            }
          ]}
          values={cultComp}
        />
      </Card>
    ))}
  </>
);

export default CulturalCompetenciesTable;
