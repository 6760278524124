import { GetUserResponse } from 'api';
import { CardEntryList } from 'components/cards/card_entry_list';

interface Props {
  userInfo: GetUserResponse['user_info'];
}

const UserInfoTable = ({ userInfo }: Props) => (
  <CardEntryList
    fieldsMap={[
      { key: 'npi', title: 'NPI' },
      { key: 'phone_number', title: 'Phone number' },
      { key: 'sms_consent', title: 'SMS consent' },
      { key: 'languages', title: 'Languages' },
      { key: 'pronouns', title: 'Pronouns' },
      { key: 'gender_identity', title: 'Gender identity' },
      { key: 'sexual_orientation', title: 'Sexual orientation' },
      { key: 'race_ethnicity', title: 'Race ethnicity' },
      { key: 'privacy_policy', title: 'Privacy policy' },
      {
        key: 'acknowledged_data_validity',
        title: 'Acknowledged data validity'
      },
      { key: 'data_source', title: 'Data source' }
    ]}
    values={userInfo}
  />
);

export default UserInfoTable;
