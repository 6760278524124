import { GetUserResponse } from 'api';
import { CardEntryList, Field } from 'components/cards/card_entry_list';

interface FurtherExperience {
  affirmation_influence_recognition: number;
  cultural_beliefs_about_health_acceptance: number;
  cultural_differences_recognition: number;
  free_text: string;
  id: string;
  inequality_within_healthcare_recognition: number;
  patients_decisions_advocation: number;
}

const experienceFieldsMap: Field<FurtherExperience>[] = [
  { key: 'free_text', title: 'Text' },
  {
    key: 'inequality_within_healthcare_recognition',
    title: 'Inequality within healthcare recognition'
  },
  {
    key: 'cultural_differences_recognition',
    title: 'Cultural differences recognition'
  },
  {
    key: 'cultural_beliefs_about_health_acceptance',
    title: 'Cultural beliefs about health acceptance'
  },
  {
    key: 'patients_decisions_advocation',
    title: 'Patients decisions advocation'
  }
];

interface Props {
  user: GetUserResponse;
}

const FurtherExperienceTable = ({ user }: Props) =>
  user.user_info.further_experience && (
    <CardEntryList
      fieldsMap={experienceFieldsMap}
      values={user.user_info.further_experience}
    />
  );

export default FurtherExperienceTable;
