import { Popconfirm, Table, Tag } from 'antd';
import { GetUserResponse } from 'api';
import { deleteOrganizationMembership } from 'api/organization_memberships';
import { DateFormatter } from 'components/date_formatter';
import { ActionsColumn } from 'components/tables';
import { MemberRoleTag } from 'components/tags/member_role_tag';
import { PopoverButton } from 'pages/organizations';
import { Link } from 'react-router-dom';
import { organizationPath } from 'utils/paths';

interface Props {
  userData: GetUserResponse;
}

const OrganizationMembershipsTable = ({ userData }: Props) => (
  <Table
    bordered
    columns={[
      {
        key: 'organization',
        render: (record: GetUserResponse['organization_memberships'][0]) => (
          <Link to={organizationPath(record.organization.id)}>{record.organization.name}</Link>
        ),
        title: 'Organization'
      },
      {
        key: 'employee_id',
        render: (record: GetUserResponse['organization_memberships'][0]) => record.employee_id,
        title: 'Employee ID'
      },
      {
        key: 'status',
        render: (record: GetUserResponse['organization_memberships'][0]) => {
          let color = 'gray';
          if (record.status === 'activated') color = 'blue';
          if (record.status === 'deactivated') color = 'red';
          return <Tag color={color}>{record.status}</Tag>;
        },
        title: 'Status'
      },
      {
        key: 'member_role',
        render: (record: GetUserResponse['organization_memberships'][0]) => (
          <MemberRoleTag value={record.member_role} />
        ),
        title: 'Member role'
      },
      {
        key: 'organization_license_tier',
        render: (record: GetUserResponse['organization_memberships'][0]) =>
          record.organization_license_tier !== undefined ? (
            record.organization_license_tier.license_tier?.name
          ) : (
            <span style={{ color: 'grey' }}>none assigned</span>
          ),
        title: 'License Tier'
      },
      {
        key: 'created_at',
        render: (record: GetUserResponse['organization_memberships'][0]) => (
          <DateFormatter value={record.created_at} />
        ),
        title: 'Created at'
      },
      {
        key: 'updated_at',
        render: (record: GetUserResponse['organization_memberships'][0]) => (
          <DateFormatter value={record.updated_at} />
        ),
        title: 'Updated at'
      },
      {
        key: 'actions',
        render: (record: GetUserResponse['organization_memberships'][0]) => {
          const removeFromOrganization = async () => {
            await deleteOrganizationMembership(record.id);
            window.location.reload();
          };

          return (
            <ActionsColumn
              customPaths={{
                remove: (
                  <Popconfirm
                    cancelText="No"
                    disabled={userData.organization_memberships.length === 1}
                    okText="Yes"
                    onConfirm={removeFromOrganization}
                    title={`Are you sure you want to remove ${userData.first_name} ${userData.last_name} from ${record.organization.name}?`}
                  >
                    <PopoverButton
                      className={userData.organization_memberships.length === 1 ? 'disabled' : ''}
                    >
                      Remove from organization
                    </PopoverButton>
                  </Popconfirm>
                )
              }}
              record={record}
            />
          );
        },
        title: 'Actions'
      }
    ]}
    dataSource={userData.organization_memberships}
    pagination={false}
    rowKey="id"
  />
);

export default OrganizationMembershipsTable;
