import { CopyOutlined } from '@ant-design/icons';
import { Button, message, Space, Tag, Tooltip } from 'antd';
import { GetUserResponse } from 'api';
import { Field } from 'components/cards/card_entry_list';

const HUBSPOT_USER_BASE_URL = process.env.REACT_APP_HUBSPOT_USER_BASE_URL;

export const userPageMainFields: Field<GetUserResponse>[] = [
  { key: 'id', title: 'ID' },
  { key: 'first_name', title: 'First name' },
  { key: 'last_name', title: 'Last name' },
  { key: 'email', title: 'E-mail' },
  { key: 'role', title: 'Role' },
  { key: 'auth0_id', title: 'Auth0 ID' },
  {
    key: 'hubspot_id',
    render: (hubspot_id: GetUserResponse['hubspot_id']) =>
      Boolean(hubspot_id) ? (
        <Space>
          <Tooltip title={hubspot_id}>
            <Button
              onClick={() => {
                navigator.clipboard.writeText(`${hubspot_id!}`);
                message.success('Copied to clipboard');
              }}
              size="small"
              type="dashed"
            >
              <CopyOutlined />
            </Button>
          </Tooltip>
          <Tooltip title={`${HUBSPOT_USER_BASE_URL}${hubspot_id}`}>
            <a
              href={`${HUBSPOT_USER_BASE_URL}${hubspot_id}`}
              rel="noopener noreferrer"
              target="_blank"
            >
              {hubspot_id}
            </a>
          </Tooltip>
        </Space>
      ) : (
        <span style={{ color: 'grey' }}>–</span>
      ),
    title: 'Hubspot ID'
  },
  { key: 'is_active', title: 'Is active' },
  { key: 'status', title: 'Status' },
  {
    key: 'status_updated_at',
    title: 'Status updated at',
    type: 'date'
  },
  { key: 'is_clinical', title: 'Is clinical' },
  { key: 'completed_onboarding', title: 'Completed onboarding' },
  { key: 'completed_onboarding_at', title: 'Completed onboarding at', type: 'date' },
  {
    key: 'permissions',
    render: (permissions: GetUserResponse['permissions']) => (
      <>
        {permissions.map((permission, i) => (
          <Tag key={i}>{permission}</Tag>
        ))}
      </>
    ),
    title: 'User permissions'
  },
  {
    key: 'professional_group',
    render: (group: GetUserResponse['professional_group']) => group?.name,
    title: 'Professional group'
  },
  {
    key: 'invite_link',
    render: (inviteLink: GetUserResponse['invite_link'], userRecord: GetUserResponse) =>
      userRecord.status === 'created' ? (
        <Space style={{ alignItems: 'flex-start' }}>
          <Tooltip title={inviteLink}>
            <Button
              onClick={() => {
                navigator.clipboard.writeText(inviteLink);
                message.success('Copied to clipboard');
              }}
              size="small"
              type="dashed"
            >
              <CopyOutlined />
            </Button>
          </Tooltip>
          <div style={{ wordBreak: 'break-all' }}>{inviteLink}</div>
        </Space>
      ) : (
        <span style={{ color: 'grey' }}>–</span>
      ),
    title: 'Invite Link'
  },
  { key: 'invited_at', title: 'Invited at', type: 'date' },
  { key: 'sign_up_source', title: 'Sign up source' },
  { key: 'created_at', title: 'Created at', type: 'date' },
  { key: 'updated_at', title: 'Updated at', type: 'date' }
];
