import { instance } from '.';

export interface GetNotificationsResponse {
  created_at: string;
  data: {
    scores?: {
      image: string;
      score_level: string;
      score_type: 'bipoc' | 'lgbq' | 'tgnc';
    }[];
  };
  id: string;
  read: boolean;
  template_name: 'new_benchmarks';
  updated_at: string;
}

export const getNotifications = (page?: string, pageSize?: string, getParams?: object) =>
  instance
    .get<ApiResponse<GetNotificationsResponse[], IndexPageMeta>>('/admin/notifications', {
      params: {
        ...getParams,
        page,
        per_page: pageSize
      }
    })
    .then(res => res.data);
