import { Card, Collapse, Divider, Spin } from 'antd';
import { getNpiRegistryData, getUser, useFetch } from 'api';
import { CardEntryList } from 'components/cards/card_entry_list';
import { NpiRegistryDatumCard } from 'pages/npi_registry';
import { useMemo } from 'react';
import { useParams } from 'react-router';

import BenchmarksSummaryTable from './userPageComponents/benchmarks_summary_table';
import CommunicationsTable from './userPageComponents/communications_table';
import CourseRatingsTable from './userPageComponents/course_ratings_table';
import CourseRegistrationsTable from './userPageComponents/course_registrations_table';
import CulturalCompetenciesTable from './userPageComponents/cultural_competencies_table';
import EducationsTable from './userPageComponents/educations_table';
import FurtherExperienceTable from './userPageComponents/further_experiences_table';
import NonClinicalExperiencesTable from './userPageComponents/non_clinical_experiences_table';
import NotificationsTable from './userPageComponents/notifications_table';
import OrganizationMembershipsTable from './userPageComponents/organization_memberships_table';
import ScoresHistoryTable from './userPageComponents/scores_history_table';
import UserExperiencesTable from './userPageComponents/user_experiences_table';
import UserInfoTable from './userPageComponents/user_info_table';
import UserPageHeader from './userPageComponents/user_page_header';
import { userPageMainFields } from './userPageComponents/user_page_main_fields';

const { Panel } = Collapse;

export const UserPage = () => {
  const { id } = useParams<{ id: string }>();
  const { data: userData, isLoading, refetch } = useFetch(getUser, [id]);

  const npiGetParams = useMemo(() => ['1', '10', { user_id: id }], [id]) as [
    string,
    string,
    object
  ];
  const { data: npiRegistryData } = useFetch(getNpiRegistryData, npiGetParams);

  if (!userData) return <Spin size="large" />;

  return (
    <div className="site-card-border-less-wrapper">
      {userData.data && (
        <Card
          headStyle={{ fontWeight: 'bold' }}
          style={{ minHeight: 'calc(100vh - 111px)' }}
          title={
            <UserPageHeader
              isLoading={isLoading}
              refetchUser={refetch}
              user={userData.data}
            />
          }
        >
          <CardEntryList
            fieldsMap={userPageMainFields}
            values={userData.data}
          />
          <Divider />
          <BenchmarksSummaryTable user_info_id={userData.data.user_info.id} />
          <Collapse>
            <Panel
              key="user_info"
              header="User Info"
            >
              <UserInfoTable userInfo={userData.data.user_info} />
            </Panel>

            <Panel
              key="org_memberships"
              header="Organization Memberships"
              style={{ paddingBottom: 0 }}
            >
              <OrganizationMembershipsTable userData={userData.data} />
            </Panel>

            <Panel
              key="educations"
              className="no-padding-panel"
              header="Educations"
            >
              <EducationsTable user_id={userData.data.id} />
            </Panel>

            <Panel
              key="experiences"
              header="Experiences"
            >
              <UserExperiencesTable experiences={userData.data.user_info.experiences} />
            </Panel>
            <Panel
              key="non_clinical_experience"
              header="Non-clinical Experiences"
            >
              <NonClinicalExperiencesTable user={userData.data} />
            </Panel>
            <Panel
              key="further_experience"
              header="Further Experience"
            >
              <FurtherExperienceTable user={userData.data} />
            </Panel>

            <Panel
              key="competences"
              header="Cultural Competences"
            >
              <CulturalCompetenciesTable
                culturalCompetences={userData.data.user_info.cultural_competences}
              />
            </Panel>

            <Panel
              key="scores"
              header="Scores History"
            >
              <ScoresHistoryTable user_info_id={userData.data.user_info.id} />
            </Panel>

            <Panel
              key="notifications"
              header="Notifications"
            >
              <NotificationsTable user_id={userData.data.id} />
            </Panel>

            <Panel
              key="emails"
              header="E-mails History"
            >
              <CommunicationsTable user_id={userData.data.id} />
            </Panel>

            <Panel
              key="ratings"
              header="Course ratings"
            >
              <CourseRatingsTable user_id={userData.data.id} />
            </Panel>

            <Panel
              key="registrations"
              header="Course registrations"
            >
              <CourseRegistrationsTable user_id={userData.data.id} />
            </Panel>

            <Panel
              key="npi_data"
              header="NPI Registry Data"
            >
              {npiRegistryData?.data &&
                npiRegistryData.data.map(datum => (
                  <NpiRegistryDatumCard
                    key={datum.id}
                    initialDatum={datum}
                  />
                ))}
            </Panel>
          </Collapse>
        </Card>
      )}
    </div>
  );
};
