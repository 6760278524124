import { ColumnsType } from 'antd/es/table';
import { getEducations, GetEducationsResponse } from 'api';
import { ApiImage } from 'components/api_image';
import { BooleanIcon } from 'components/boolean_icon';
import { DateFormatter } from 'components/date_formatter';
import { PdfLink } from 'components/pdf_link';
import { DataTable } from 'components/tables';
import { Link } from 'react-router-dom';
import { coursePath } from 'utils/paths';

const educationColumns: ColumnsType<GetEducationsResponse> = [
  {
    key: 'course_name',
    render: (education: User['user_info']['educations'][0]) =>
      education.course_id !== null ? (
        <Link to={coursePath(education.course_id)}>{education.course_name}</Link>
      ) : (
        education.course_name
      ),
    title: 'Course name'
  },
  {
    key: 'organization',
    render: (education: User['user_info']['educations'][0]) => education.organization,
    title: 'Organization'
  },
  {
    key: 'year',
    render: (education: User['user_info']['educations'][0]) => education.year,
    title: 'Year'
  },
  {
    key: 'credits',
    render: (education: User['user_info']['educations'][0]) => education.credits,
    title: 'Credits'
  },
  {
    key: 'bipoc_tag',
    render: (education: User['user_info']['educations'][0]) => (
      <BooleanIcon value={Boolean(education.bipoc_tag)} />
    ),
    title: 'BIPOC'
  },
  {
    key: 'lgbq_tag',
    render: (education: User['user_info']['educations'][0]) => (
      <BooleanIcon value={Boolean(education.lgbq_tag)} />
    ),
    title: 'LGBQ'
  },
  {
    key: 'tgnc_tag',
    render: (education: User['user_info']['educations'][0]) => (
      <BooleanIcon value={Boolean(education.tgnc_tag)} />
    ),
    title: 'TGNC'
  },
  {
    key: 'image',
    render: (education: User['user_info']['educations'][0]) =>
      education.image.url !== null ? (
        education.image.url.endsWith('.pdf') ? (
          <PdfLink url={education.image.url} />
        ) : (
          <ApiImage
            image={education.image}
            style={{ height: '50px', width: 'auto' }}
          />
        )
      ) : (
        'No file attached'
      ),
    title: 'Image'
  },
  {
    key: 'course_id',
    render: (education: User['user_info']['educations'][0]) => (
      <BooleanIcon value={Boolean(education.course_id)} />
    ),
    title: 'Complete'
  },
  {
    key: 'created_at',
    render: (education: User['user_info']['educations'][0]) => (
      <DateFormatter
        format="MM/DD/YYYY hh:mm a"
        value={education.created_at}
      />
    ),
    title: 'Created at'
  },
  {
    key: 'updated_at',
    render: (education: User['user_info']['educations'][0]) => (
      <DateFormatter
        format="MM/DD/YYYY hh:mm a"
        value={education.updated_at}
      />
    ),
    title: 'Updated at'
  }
];

interface Props {
  user_id: string;
}

const EducationsTable = ({ user_id }: Props) => (
  <DataTable
    columns={educationColumns}
    filters={[
      {
        key: 'course_name',
        label: 'Course name'
      }
    ]}
    getMethod={getEducations}
    getParams={{
      user_id
    }}
    rowKey="id"
  />
);

export default EducationsTable;
