import { Button, Dropdown, Popconfirm } from 'antd';
import { deleteUser, GetUserResponse, sendInviteEmail, updateUser, UpdateUserParams } from 'api';
import { CardHeader } from 'components/cards';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';
import { userEditPath, userScoringPath, usersPath } from 'utils/paths';

interface Props {
  isLoading: boolean;
  refetchUser: () => void;
  user: GetUserResponse;
}

const UserPageHeader = ({ isLoading, refetchUser, user }: Props) => {
  const navigate = useNavigate();
  const [isUpdating, setIsUpdating] = useState(false);

  const sendUpdateRequest = (userParams: UpdateUserParams['user']) => {
    if (isUpdating) return;

    setIsUpdating(true);
    updateUser(user.id, { user: userParams }).finally(() => {
      refetchUser();
      setIsUpdating(false);
    });
  };

  return (
    <CardHeader
      customPaths={[{ name: 'Score user', path: userScoringPath(user.id) }]}
      editPath={userEditPath(user.id)}
      title={` User ${user.first_name} ${user.last_name}`}
    >
      <Dropdown
        menu={{
          items: [
            {
              key: 'send_manual_invite',
              label: (
                <Popconfirm
                  cancelText="Cancel"
                  okText="Send"
                  onConfirm={() => sendInviteEmail(user.id)}
                  title="This will send a new link to users who cannot access their original invitation
                          link and will replace any previous links provided. To confirm this action click below."
                >
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a>Send invite</a>
                </Popconfirm>
              )
            },
            {
              disabled: isUpdating || isLoading,
              key: 'toggle_onboarding',
              label: (
                <Popconfirm
                  cancelText="Cancel"
                  okText="Ok"
                  onConfirm={() =>
                    sendUpdateRequest({
                      completed_onboarding: !user.completed_onboarding
                    })
                  }
                  title={`This will update this user's onboarding status to ${
                    user.completed_onboarding ? 'FALSE' : 'TRUE'
                  }. To confirm this action click below.`}
                >
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a>Toggle onboarding</a>
                </Popconfirm>
              )
            },
            {
              danger: true,
              disabled: isLoading || isUpdating || user.status === 'created',
              key: 'change_status',
              label: (
                <Popconfirm
                  cancelText="Cancel"
                  okText="Yes"
                  onConfirm={() =>
                    sendUpdateRequest({
                      status: user.status === 'deactivated' ? 'activated' : 'deactivated'
                    })
                  }
                  title={`Are you sure you want to ${
                    user.status === 'deactivated' ? 'activate' : 'deactivate'
                  } this user?`}
                >
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a>{user.status === 'deactivated' ? 'Activate' : 'Deactivate'} </a>
                </Popconfirm>
              )
            },
            {
              danger: true,
              disabled: isLoading || isUpdating,
              key: 'delete',
              label: (
                <Popconfirm
                  cancelText="Cancel"
                  okText="Yes"
                  onConfirm={() => deleteUser(user.id).then(() => navigate(usersPath))}
                  title="Are you sure you want to delete this record?"
                >
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a>Delete</a>
                </Popconfirm>
              )
            }
          ]
        }}
      >
        <Button
          size="large"
          style={{}}
        >
          Actions
        </Button>
      </Dropdown>
    </CardHeader>
  );
};

export default UserPageHeader;
