import { ColumnsType } from 'antd/es/table';
import { getCourseRegistrations, GetCourseRegistrationsResponse } from 'api';
import { DateFormatter } from 'components/date_formatter';
import { ActionsColumn, DataTable } from 'components/tables';
import { Link } from 'react-router-dom';
import { coursePath, courseRegistrationPath } from 'utils/paths';

const courseRegistrationColumns: ColumnsType<GetCourseRegistrationsResponse> = [
  {
    key: 'course',
    render: (_value, record) => {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (record.course !== null) {
        return <Link to={coursePath(record.course.id)}>{record.course.short_title}</Link>;
      } else {
        return 'Course not found. This could be because the original course was updated to use a different scormcloud course.';
      }
    },
    title: 'Course'
  },
  {
    dataIndex: 'created_at',
    key: 'created_at',
    render: (value: GetCourseRegistrationsResponse['created_at']) => (
      <DateFormatter value={value} />
    ),
    title: 'Created at'
  },
  {
    dataIndex: 'completed_at',
    key: 'completed_at',
    render: (value: GetCourseRegistrationsResponse['completed_at']) => (
      <DateFormatter value={value} />
    ),
    title: 'Completed at'
  },
  {
    dataIndex: 'total_seconds_tracked',
    key: 'total_seconds_tracked',
    title: 'Seconds tracked'
  },
  {
    render: (_value, record) => (
      <ActionsColumn
        record={record}
        showPath={courseRegistrationPath(record.id.toString())}
      />
    ),
    title: 'Actions'
  }
];

interface Props {
  user_id: string;
}

const CourseRegistrationsTable = ({ user_id }: Props) => (
  <DataTable
    columns={courseRegistrationColumns}
    getMethod={getCourseRegistrations}
    getParams={{
      user_id
    }}
  />
);

export default CourseRegistrationsTable;
