import { BooleanIcon } from 'components/boolean_icon';
import { DateFormatter } from 'components/date_formatter';
import { ReactNode } from 'react';

import { CardEntry } from './card_entry';

type EntryTypeOption = 'date';

export interface Field<T> {
  key: keyof T;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  render?: (value: any, record: T) => ReactNode;
  title: string;
  type?: EntryTypeOption;
}

interface CardEntryListProps<T> {
  fieldsMap: Field<T>[];
  values: T;
}

const getContent = <
  T extends Record<keyof T, string[] | boolean | number | object | string | null | undefined>
>(
  value: T[keyof T],
  type?: EntryTypeOption
): ReactNode => {
  if (value === undefined || value === null) {
    return <span style={{ color: 'grey' }}>–</span>;
  }
  if (typeof value === 'boolean') {
    return <BooleanIcon value={value} />;
  }
  if (type === 'date') {
    return <DateFormatter value={value as string} />;
  }
  if (Array.isArray(value)) {
    return value.length > 0 ? value.join(', ') : <span style={{ color: 'grey' }}>–</span>;
  }
  if (typeof value === 'object') {
    return Object.values(value).join(', ');
  }
  return value;
};

export const CardEntryList = <
  T extends Record<keyof T, string[] | boolean | number | object | string | null | undefined>
>({
  fieldsMap,
  values
}: CardEntryListProps<T>) => (
  <>
    {fieldsMap.map(({ key, render, title, type }, index) => {
      const value = values[key];

      const content = render ? render(value, values) : getContent<T>(value, type);

      return (
        <CardEntry
          key={index}
          title={title}
        >
          {content}
        </CardEntry>
      );
    })}
  </>
);
