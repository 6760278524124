import { Card } from 'antd';
import { GetUserResponse } from 'api';
import { CardEntryList, Field } from 'components/cards/card_entry_list';
import { DateFormatter } from 'components/date_formatter';

const experienceFieldsMap: Field<GetUserResponse['user_info']['experiences'][0]>[] = [
  { key: 'organization', title: 'Organization' },
  { key: 'job_title', title: 'Job title' },
  {
    key: 'average_panel_size',
    title: 'Average panel size'
  },
  { key: 'lgbq_percentage', title: 'LGBQ percentage' },
  { key: 'bipoc_percentage', title: 'BIPOC percentage' },
  { key: 'tgnc_percentage', title: 'TGNC percentage' },
  {
    key: 'start_date',
    title: 'Start date',
    type: 'date'
  },
  { key: 'end_date', title: 'End date', type: 'date' },
  {
    key: 'created_at',
    render: (value: GetUserResponse['user_info']['experiences'][0]['created_at']) => (
      <DateFormatter
        format="MM/DD/YYYY hh:mm a"
        value={value}
      />
    ),
    title: 'Created at',
    type: 'date'
  },
  {
    key: 'updated_at',
    render: (value: GetUserResponse['user_info']['experiences'][0]['updated_at']) => (
      <DateFormatter
        format="MM/DD/YYYY hh:mm a"
        value={value}
      />
    ),
    title: 'Updated at',
    type: 'date'
  }
];

interface Props {
  experiences: GetUserResponse['user_info']['experiences'];
}

const UserExperiencesTable = ({ experiences }: Props) => (
  <>
    {experiences.map((experience, index) => (
      <Card
        key={index}
        title={experience.organization}
      >
        <CardEntryList
          fieldsMap={experienceFieldsMap}
          values={experience}
        />
      </Card>
    ))}
  </>
);

export default UserExperiencesTable;
